import { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import * as Sentry from '@sentry/remix'

if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
        tracesSampleRate: 1,
        replaysOnErrorSampleRate: 1,
        integrations: [
            Sentry.browserTracingIntegration({
                useEffect,
                useLocation,
                useMatches,
            }),
            Sentry.replayIntegration(),
        ],
    })
}

startTransition(() => {
    hydrateRoot(
        document,
        <StrictMode>
            <RemixBrowser />
        </StrictMode>
    )
})
